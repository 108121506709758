.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  gap: 14px;
  overflow: scroll;
}

.bankWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.selectWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.bankWrapperText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.amountWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label {
  margin-left: 16px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  letter-spacing: 0.4px;
}

.footer {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: 530px;
  display: flex;
  padding: 24px;
  background-color: var(--CFD-theme-Surface-1);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnContainer {
  width: 100%;
}

.btnText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 25px;
}

.contactUs > span {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

/* .contactUs button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: var(--CFD-theme-System-Tertiary);
} */

@media screen and (max-width: 599px) {
  .wrapper {
    height: 100vh;
    padding-bottom: 150px;
  }
  .footer {
    width: 100%;
  }

  .contactUs {
    margin-top: 15px;
    font-size: 12px;
  }

  .contactUs button {
    font-size: 12px;
  }
}

