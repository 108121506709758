.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  gap: 14px;
  overflow: scroll;
}

.searchOtherWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.searchOtherWrapperText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.otherWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.otherWrapperText {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-Outline);
}

.popularWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.popularContainer {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center; 
  gap: 10px;
}


.popular {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 300px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--CFD-theme-Surface-5);
}

.imgContainer {
  width: 136px;
  height: 40px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.polularLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 200px;
  gap: 10px
}

.popularLink {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-Primary);
  text-decoration: none;
}

.popularBtn {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
}

.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 25px;
}

.contactUs > span {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

/* .contactUs button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: var(--CFD-theme-System-Tertiary);
} */

@media screen and (max-width: 1239px) {
  .popularWrapper {
    justify-content: space-between;
  }
  .popular {
    width: 500px;
  }
}

@media screen and (max-width: 904px) {
  .popular {
    width: 540px;
  }
}

@media screen and (max-width: 599px) {
  .popular {
    width: 360px;
  }

  .contactUs {
    margin-top: 15px;
    font-size: 12px;
  }

  .contactUs button {
    font-size: 12px;
  }
}

@media screen and (max-width: 412px) {
  .popular {
    width: 260px;
  }
}

@media screen and (min-width: 2000px) {
  .popular {
    width: 480px;
  }
}