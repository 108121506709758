.chartContainer {
    border-radius: 6px;
    border: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
    background-color: var(--CFD-theme-Surface-1);
    width: 100%;
    height: 100%;
    min-width: 320px;
}

.chartContainerHeader {
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: var(--CFD-theme-System-OnSurface);
    height: 65px;
    padding: 0 16px;
}

@media screen and (max-width: 375px) {
    .chartContainerHeader {
      max-height: 36px;
    }
  }