.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.container {
  position: relative;
  width: 100%;
  height: calc(100% - 116px);
  min-height: 360px;
}

.menuWrapper {
  width: 360px;
  min-height: 360px;
  max-width: 360px;
  box-sizing: border-box;
  position: fixed;
  border-radius: 14px;
  right: 16px;
  background-color: var(--CFD-theme-Surface-2);
  z-index: 10;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);
}

.menuHeader {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  width: 100%;
  height: 72px;
  background-color: var(--CFD-theme-Surface-2);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.menuMobileHeader {
  display: none;
}

.menuHeaderNavigationText {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.btnContainer {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.userIcon {
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  background-color: var(--CFD-theme-System-Primary);
  border-radius: 50%;
}

.headerTitle {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--CFD-theme-System-OnSurface);
}

.menuBody {
  box-sizing: border-box;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--CFD-theme-Surface-2);
  max-height: 300px;
  width: 100%;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  gap: 12px;
}

.mainMenuContainerLanguage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.themeBtn {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 16px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--CFD-theme-Surface-3);
}

.menuHeaderAccount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}

.menuHeaderAccountInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.menuHeaderAccountInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.menuHeaderAccountInfoTitle {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.menuHeaderAccountInfoEmail {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  word-break: break-all;
}

.menuHeaderAccountLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  gap: 8px;
}

.menuHeaderAccountLinkText {
  color: var(--CFD-theme-System-Primary);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.withdrawBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btnContainer {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.btnTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.mainMenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.mainMenuText {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.mainMenu {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--CFD-theme-Surface-3);
}

.menuCell {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 16px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.borderTop {
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.menuCellText {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
  white-space: nowrap;
}

.languageTextContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 110px;
}

.languageContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 16px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px 8px 16px;
  width: 100%;
  background-color: var(--CFD-theme-Surface-2);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.btnFooterContainer {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .background {
    background-color: var(--CFD-theme-Surface-2);
  }

  .menuWrapper {
    position: fixed;
    min-width: 100%;
    top: 0;
    right: 0;
    min-height: 100dvh;
  }

  .menuMobileHeader {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    width: 100%;
    height: 72px;
    background-color: var(--CFD-theme-Surface-2);
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
  }

  .menuBody {
    overflow: auto;
    min-height: 100dvh;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 12;
  } 
}

.languageIcon {
  border-radius: 20px;
  width: 22px;
  height: 22px;
}