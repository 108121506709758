.tvChart {
    height: calc(100% - 75px);
}

.tvChart iframe {
    width: 100%;
}

@media screen and (max-width: 375px) {
    .tvChart {
        height: calc(100% - 35px);
    }
  }